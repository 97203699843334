
import { defineComponent, ref } from 'vue';
import { scrollToComponent, copyToClipboard } from './utils';
import InputText from 'primevue/inputtext';

export default defineComponent({
  props: {
    view: {
      type: String,
    },
    isMobile: Boolean,
  },
  components: {
    InputText,
  },
  setup() {
    const currentYear = ref(new Date().getFullYear());

    const openExternalLink = (link: string) => {
      window.open(link, '_blank');
    }

    return {
      scrollToComponent,
      copyToClipboard,
      currentYear,
      openExternalLink
    };
  },
});
