
import Carousel from 'primevue/carousel';
import {
  defineComponent, ref, computed, onMounted, onUnmounted
} from 'vue';
import { useRouter } from 'vue-router';


export default defineComponent({
  components: {
    Carousel
  },
  setup() {
    const router = useRouter();
    const reviewList = ref([
      {
        id: 1,
        title: 'What Do Brands And Influencers Say?',
        name: 'Anime Balls Deep',
        designation: 'YouTube Influencers',
        description: `We're always looking for new ways to monetize our content, 
        and G.IO made it incredibly easy to do just that. It's great!`,
        star: 5,
      },
      {
        id: 2,
        title: 'What Do Brands And Influencers Say?',
        name: 'LiamDoesGame',
        designation: 'YouTube Influencers',
        star: 5,
        description: `I'm always checking the website for any new campaigns since it makes me super excited to work with brands and different games.

        I would definitely recommend G.IO to other influencers because it can help influencers financially and open the doors to new experiences.       `,
      },
      {
        id: 3,
        title: 'What Do Brands And Influencers Say?',
        name: 'JVDB studios',
        designation: 'Talent Agency',
        star: 5,
        description: `Working with G.IO has been a positive experience for us. We have partnered on multiple campaigns and have consistently found the briefings to be clear, yet allowing room for creativity.

                  The support team is great, providing quick turnaround times that result in no conflict with our uploading schedule.`,
      },
      {
        id: 4,
        title: 'What Do Brands And Influencers Say?',
        name: 'PONOS',
        designation: 'Video Game Company',
        star: 5,
        description: `Results have been great!`,
      },
      {
        id: 5,
        title: 'What Do Brands And Influencers Say?',
        name: 'AAX',
        designation: 'Crypto Exchange Platform',
        star: 5,
        description: 'Very professional team and great communication.',
      },
      {
        id: 6,
        title: 'What Do Brands And Influencers Say?',
        name: 'JVDB studios',
        designation: 'Talent Agency',
        star: 5,
        description: `Working with G.IO has been a positive experience for us. We have partnered on multiple campaigns and have consistently found the briefings to be clear, yet allowing room for creativity.
          The support team is great, providing quick turnaround times that result in no conflict with our uploading schedule.`,
      },
    ]);

    const allImage = ref([
      require('../../assets/landing/Influencer-image-1.png'),
      require('../../assets/landing/Influencer-image-2.png'),
      require('../../assets/landing/Influencer-image-3.png'),
      require('../../assets/landing/Influencer-image-4.png'),
      require('../../assets/landing/Influencer-image-5.png'),
      require('../../assets/landing/Influencer-image-6.png'),
    ]);

    const currentReview = ref(0);

    const formattedDescription = computed(() => {
      return reviewList.value[currentReview.value].description.replace(/\n/g, '<br>');
    });

    const handleLoginClick = () => {
      router.push({ name: 'login' }).catch((err) => {
        console.error('Failed to navigate:', err);
      });
    };

    const handleChangeReview = (index: any) => {
      if (index != '5') currentReview.value = index;
    };

    const isMobile = ref(false);

    onMounted(() => {
      const checkIsMobile = () => {
        isMobile.value = window.innerWidth <= 479;
      };

      checkIsMobile();
      window.addEventListener('resize', checkIsMobile);

      const reviewInterval = setInterval(() => {
        if (currentReview.value < 4)  { 
          currentReview.value++;
        } else {
          currentReview.value = 0; 
        }
  }, 4000);

      onUnmounted(() => {
        window.removeEventListener('resize', checkIsMobile);
        clearInterval(reviewInterval);
      });
    });

    const filteredReviewList = computed(() => {
      return isMobile.value ? reviewList.value.slice(0, -1) : reviewList.value;
    });

    const filteredAllImageList = computed(() => {
      return isMobile.value ? allImage.value.slice(0, -1) : allImage.value;
    });

    const carouselResponsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 1,
        numScroll: 1,
      },
      {
        breakpoint: '768px',
        numVisible: 1,
        numScroll: 1,
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1,
      },
    ];

    return {
      reviewList: filteredReviewList,
      currentReview,
      allImage: filteredAllImageList,
      handleChangeReview,
      handleLoginClick,
      formattedDescription,
      isMobile,
      carouselResponsiveOptions
    };
  },
});
