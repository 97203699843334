import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b35605ba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "layout-topbar-icons p-d-flex p-justify-between p-align-center" }
const _hoisted_2 = { class: "p-d-flex p-ai-lg-center p-flex-wrap p-align-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Gamification = _resolveComponent("Gamification")!
  const _component_Notifications = _resolveComponent("Notifications")!
  const _component_creator_menu = _resolveComponent("creator-menu")!
  const _component_Menu = _resolveComponent("Menu")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["layout-topbar", { 'layout-topbar-big': !_ctx.isSmallSidebar, 'scroll-topbar': !_ctx.scrollY,
  'background-dark': _ctx.isNewDesignEnabled, 'topbar-with-warning': _ctx.isNewDesignEnabled && !_ctx.isDashboard && !_ctx.isWhiteListing}])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.isNewDesignEnabled && _ctx.userIsBrand)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              class: "new-button",
              onClick: _ctx.goToCreateCampaign
            }, {
              default: _withCtx(() => [
                _createTextVNode("+ New Campaign")
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true),
        (_ctx.isNewDesignEnabled && _ctx.userIsCreator)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 1,
              class: "new-button",
              onClick: _ctx.goToCreateContent
            }, {
              default: _withCtx(() => [
                _createTextVNode("+ Create Content")
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true),
        _createVNode(_component_Gamification, { style: {"display":"none"} }),
        _createVNode(_component_Notifications, { "is-brand": _ctx.userIsBrand }, null, 8, ["is-brand"]),
        _createVNode(_component_creator_menu),
        _createVNode(_component_Menu, {
          id: "menu",
          ref: "menuRef",
          model: _ctx.userMenuItems,
          popup: true,
          appendTo: 'body'
        }, null, 8, ["model"])
      ])
    ])
  ], 2))
}