<template>
  <div class="landing-carousel">
    <Carousel :value="carouselItems" :numScroll="1" :circular="true" autoPlay="false" :autoplayInterval="0"
      :showIndicators="true" :showNavigators="false" :responsiveOptions="responsiveOptions"
      class="landing-carousel-wrap" @change="onSlideChange">
      <template v-slot:item="slotProps">
        <div class="slide-height">
          <transition name="fade" @before-enter="beforeEnter" @enter="enter" @leave="leave">
            <div :key="currentSlideIndex" class="media-background">
              <img v-if="slotProps.data.backgroundImage && !slotProps.data.videoLink"
                :src="slotProps.data.backgroundImage" class="background-image" alt="Slide Image" />
              <video v-if="slotProps.data.videoLink" :src="slotProps.data.videoLink" class="background-video" autoplay
                muted loop preload="auto" playsinline>
              </video>
            </div>
          </transition>
        </div>
      </template>
    </Carousel>
    <motion.div class="p-flex-column landing-header" v-motion :initial="{ opacity: 0, x: -50 }"
      :enter="{ opacity: 1, x: 0, y: 0 }" :variants="{ custom: { scale: 2 } }" :delay="100" :duration="1200">
      <div class="io-text">GameInfluencer.IO</div>
      <div class="io-connect">{{ title }}</div>
      <div class="io-text-long-influencer">Your Gateway to Scalable Performance-Based Campaigns</div>
      <button class="join-btn" @click="$router.push({ name: 'login' })">JOIN NOW</button>
    </motion.div>
    <div class="landing-footer">
      <div class="landing-page-container">
        <div class="p-d-flex p-ai-center camp-footer">
          <div class="info">
            <img src="../../assets/SteelSeries.png" class="camp-image" />
            <div class="footer-text">
              <div class="p-flex-column">
                <div class="camp-name">New {{ currentCampaign.name }} Campaign!</div>
                <div class="camp-footer-text">
                  Test Arctis NOVA 5 console headsets!
                </div>
              </div>
            </div>
          </div>
          <div class="action">
            <button class="explore-btn join-btn" @click="$router.push({ name: 'login' })">EXPLORE</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Carousel from 'primevue/carousel';
import { defineComponent, ref, onMounted } from 'vue';
import { motion } from '@vueuse/motion';

export default defineComponent({
  components: {
    Carousel
  },
  props: {
    view: {
      type: String,
    },
    currentCampaign: {
      name: String,
      amount: String,
      logoPath: String,
    }
  },
  setup() {
    const titles = ref(['Connect', 'Create', 'Promote', 'Track', 'Enjoy']);
    const title = ref('');
    const typing = ref(false);
    const currentIndex = ref(0);
    const typeSpeed = ref(100);
    const eraseDelay = ref(1000);
    const nextTitleDelay = ref(0);
    const currentSlideIndex = ref(0);

    onMounted(() => {
      typeTitle();
    });

    const typeTitle = () => {
      typing.value = true;
      const fullTitle = titles.value[currentIndex.value];
      let i = 0;
      const typeInterval = setInterval(() => {
        title.value += fullTitle[i];
        i++;
        if (i === fullTitle.length) {
          clearInterval(typeInterval);
          setTimeout(() => {
            eraseTitle();
          }, eraseDelay.value);
        }
      }, typeSpeed.value);
    };

    const eraseTitle = () => {
      const fullTitle = titles.value[currentIndex.value];
      let i = fullTitle.length - 1;
      const eraseInterval = setInterval(() => {
        title.value = title.value.slice(0, -1);
        i--;
        if (i < 0) {
          clearInterval(eraseInterval);
          typing.value = false;
          setTimeout(() => {
            showNextTitle();
          }, nextTitleDelay.value);
        }
      }, typeSpeed.value);
    };

    const showNextTitle = () => {
      currentIndex.value = (currentIndex.value + 1) % titles.value.length;
      typeTitle();
    };

    const onSlideChange = (event) => {
      currentSlideIndex.value = event.index;
    };

    const beforeEnter = (el) => {
      el.style.opacity = 0.9;
    };

    const enter = (el, done) => {
      el.style.transition = 'opacity 2s ease';
      el.style.opacity = 1;
      done();
    };

    const leave = (el, done) => {
      el.style.transition = 'opacity 2s ease';
      el.style.opacity = 0.8;
      done();
    };

    const carouselItems = ref([
      {
        backgroundImage: require('@/assets/landing/campaign1.avif'),
        videoLink: require('@/assets/landing/video-banner.mp4'),
      }
    ]);

    const responsiveOptions = ref([
      {
        breakpoint: '2500px',
        numVisible: 1,
        numScroll: 1
      },
      {
        breakpoint: '1900px',
        numVisible: 1,
        numScroll: 1
      },
      {
        breakpoint: '1400px',
        numVisible: 1,
        numScroll: 1
      },
      {
        breakpoint: '1199px',
        numVisible: 3,
        numScroll: 1
      },
      {
        breakpoint: '767px',
        numVisible: 1,
        numScroll: 1
      },
      {
        breakpoint: '575px',
        numVisible: 1,
        numScroll: 1
      }
    ]);

    return {
      title,
      carouselItems,
      responsiveOptions,
      currentIndex,
      currentSlideIndex,
      onSlideChange,
      beforeEnter,
      enter,
      leave,
      motion
    };
  },
});
</script>

<style scoped lang="scss">
* {
  font-family: 'Montserrat', sans-serif;
}

.image-background {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 900px;
  background-blend-mode: overlay;
  background-color: rgba(0, 0, 0, 0.6);
  background-size: cover;
}

.page-background {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background: linear-gradient(to bottom right, #130A23 14.17%, rgba(19, 10, 35, 0) 88%);

}

.io-text-long-influencer {
  font-weight: 500;
  font-size: 32rem;
  color: #FFFFFF;
  line-height: 39px;
  width: 100%;
}

.landing-footer {
  line-height: 1.5;
  width: 100%;
  background-color: #E73D38;
  margin: 0;
  position: relative;
  margin-top: -1px;
  margin-top: -7%;
}

.camp-footer {
  margin-left: 0;
  padding: 30px 0px;

  .info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 85%;
  }

  .action {
    width: auto;
  }
}

.camp-image {
  width: 86px;
  height: 86px;
}

.footer-text {
  margin-left: 2%;
  margin-right: 2%;
}

.camp-name {
  color: #FFFFFF;
  font-size: 30rem;
  font-weight: 600;
}

.camp-footer-text {
  color: #FFFFFF;
  font-size: 24rem;
}

.explore-btn {
  background: #FFFF;
  color: #E6343C;
  border: 1px solid transparent;
}

.slide-height {
  position: relative;
  margin-left: -1px;
  margin-top: -1px;
}

.explore-btn:hover {
  background: #E73D38;
  color: #FFFFFF;
  border-color: #fff;
}

.landing-header {
  position: absolute;
  top: 27%;
  text-align: center;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.io-text {
  font-weight: 700;
  font-size: 36rem;
  color: #E73D38;
  line-height: 43px;
}

.io-connect {
  font-weight: 800;
  font-size: 128rem;
  color: #FFFFFF;
  line-height: 156px;
  margin-top: 2%;
  height: 156rem;
}

.join-btn {
  margin-top: 70px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0.8;
}

.media-background {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  height: 1000px;

  &:before {
    content: '';
    position: absolute;
    background-blend-mode: overlay;
    background: rgba(0, 0, 0, 0.6);
    background-size: cover;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 999;
  }

  .background-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-size: cover;
  }

  .background-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    scale: 1.07;
  }
}





@media screen and (min-width: 767px) {
  .explore-btn {
    margin-top: 0px !important;
  }
}

@media screen and (min-width: 2100px) {
  .landing-footer {
    margin-top: auto;
  }
}

@media screen and (max-width: 1400px) {

  .image-background,
  .media-background {
    height: 700px;
  }

  .camp-name {
    font-size: 28rem
  }

  .camp-footer-text {
    font-size: 22rem;
  }

  .io-text {
    font-size: 32rem;
  }

  .io-connect {
    font-size: 90rem;
    height: 90rem;
    line-height: 90rem;
  }

  .io-text-long-influencer {
    font-size: 28rem;
  }
}


@media screen and (max-width: 1100px) {

  .image-background,
  .media-background {
    height: 700px;
  }

  .camp-name {
    font-size: 28rem
  }

  .camp-footer-text {
    font-size: 22rem;
  }

  .io-text {
    font-size: 32rem;
  }

  .io-connect {
    font-size: 90rem;
    height: 90rem;
    line-height: 90rem;
  }

  .io-text-long-influencer {
    font-size: 28rem;
  }

  .join-btn {
    margin-top: 5%;
    font-size: 20rem;
    width: 150rem;
    height: 45rem;
  }

  .landing-header {
    top: 30%;
  }
}

@media screen and (max-width: 1000px) {
  .camp-footer-text {
    font-size: 18rem;
  }
}

@media screen and (max-width: 800px) {
  .camp-name {
    font-size: 24rem
  }

  .camp-footer-text {
    font-size: 18rem;
  }

  .explore-btn {
    //margin-left: 4vw;
  }

  .io-text {
    font-size: 28rem;
  }

  .io-connect {
    font-size: 80rem;
    height: 80rem;
    line-height: 80rem;
    margin-bottom: 20px;
  }

  .io-text-long-influencer {
    font-size: 24rem;
    line-height: 26rem;
  }

  .image-background {
    height: 600px;
  }


  .join-btn {
    margin-top: 5%;
    font-size: 18rem;
    width: 150rem;
    height: 40rem;
  }


}

@media screen and (max-width: 768px) {
  .image-background {
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 500px;
  }

  .media-background {
    height: 500px;

    .background-video {
      scale: 1.2;
      position: relative;
      top: 26px;
    }
  }

  .page-background {
    background-image: linear-gradient(to bottom, rgba(19, 10, 35, 0.8), rgba(19, 10, 35, 0.2));
  }

  .camp-image {
    margin: 2% auto;
    display: block;
  }

  .camp-name {
    text-align: center
  }

  .camp-footer {
    flex-direction: column;
    margin-left: 0%;

    .info {
      width: 100%;
      flex-direction: column;
    }
  }

  .camp-footer-text {
    text-align: center
  }

  .explore-btn {
    display: block;
  }

  .landing-header {
    position: absolute;
    z-index: 111;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 0;
  }

  .io-text {
    text-align: center;
    margin-top: 130px;
  }

  .io-connect {
    text-align: center;
    font-size: 80px;
    height: 80px;
    line-height: 80px;
  }

  .io-text-long-influencer {
    text-align: center;
    margin: auto;
    width: 360px;
  }

  .join-btn {
    margin: 0px auto;
    margin-top: 25px;
  }

  .landing-footer {
    margin-top: -32px;
  }
}

@media screen and (max-width: 578px) {
  .camp-name {
    font-size: 16rem;
  }

  .camp-footer-text {
    font-size: 14rem;
  }

  .explore-btn {
    //margin: 20px auto;
    position: static !important;
  }

  .image-background {
    height: 500px;
  }

  .media-background {
    height: 500px;
  }

  .landing-header {
    top: 40px;
  }

  .camp-footer {
    padding: 20px 0;
  }


  .io-text {
    margin-top: 130px;
    font-size: 23rem;
  }

  .io-connect {
    font-size: 50px;
    height: 50px;
    line-height: 50px;
  }


  .io-text-long-influencer {
    font-size: 20rem;
  }


  .join-btn {
    font-size: 14rem;
    width: 136rem;
    height: 34rem;
  }
}

@media screen and (max-width: 413px) {
  .page-background {
    height: 100%;
  }

  .image-background {
    height: 450px;
  }

  .media-background {
    height: 400px;
  }

  .camp-name {
    font-size: 18rem;
  }

  .camp-footer-text {
    font-size: 12rem
  }

  .landing-header {
    position: absolute;
    top: 40px;
  }

  .io-connect {
    font-size: 40rem;
    line-height: 40rem;
    height: 40rem;
  }

  .io-text-long-influencer {
    font-size: 18rem;
    font-weight: 500;
    width: 300px;
  }

  .join-btn {
    position: absolute;
    left: 28%;
    top: 100%;
  }

  .landing-header {
    top: 0;
    padding: 0 20px;
  }
}
</style>
